









































































import _ from "lodash"
import { Component, Prop, Watch } from "vue-property-decorator"
import Vue from "vue"
import filters from "@/filters"
import ConfirmSeatDialog from "@/components/ConfirmSeatDialog.vue"

@Component({
  components: { ConfirmSeatDialog },
  filters: filters,
})
export default class TicketCard extends Vue {
  @Prop() zone!: any
  @Prop() seat!: any

  @Prop() selectedConcessionId!: number
  @Prop() cartIndex!: number
  @Prop() hasFees!: boolean

  // SeatNoticeModal
  seatWithNotice: any = null

  concessionId: number | null = null
  initialized: boolean = false

  mounted() {
    this.concessionId = this.selectedConcessionId
    this.initialized = true
  }

  get selectedConcession() {
    return _.find(this.zone.concessions, { id: this.concessionId })
  }

  removeSeat() {
    this.$store.dispatch("shoppingCart/update", ["removeLine", this.cartIndex])

    const price = this.selectedConcession
      ? this.selectedConcession.ticket_price
      : this.zone.ticket_price

    this.$emit("seatRemoved", price)
  }

  get isNumbered() {
    return this.zone != null && this.zone.seat_type == "numbered"
  }

  onConcessionIdChange(concessionId: number) {
    if (!this.initialized) return
    this.$store.dispatch("shoppingCart/update", [
      "setConcessionId",
      {
        index: this.cartIndex,
        concessionId: concessionId,
      },
    ])

    // Aquí debemos indicar el cambio a GTM
    // disparamos evento para notificar que se debe
    // lanzar el evento de GTM de cambio de carrito
    // lo hacemos aquí porque hay que enviar todo el carrito
    // no solo uno de los items
    this.$emit("concessionChanged")
  }
  @Watch("selectedConcessionId")
  onSelectedConcessionId() {
    this.concessionId = this.selectedConcessionId
  }
}
