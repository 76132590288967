







import Vue from "vue"
import { Component } from "vue-property-decorator"
import { mapState } from "vuex"

@Component({
  computed: {
    ...mapState({
      cart: (state: any) => state.shoppingCart.cart,
    }),
  },
})
export default class CartIcon extends Vue {
  get numTickets() {
    return this.$store.state.shoppingCart.cart.length
  }
}
