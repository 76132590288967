






















































import _ from "lodash"
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import filters from "@/filters"

@Component({
  filters: filters,
})
export default class CalendarChangeSession extends Vue {
  @Prop() event!: Dictionary<any> | null

  @Prop({
    type: Object,
    required: true,
    default: null,
  })
  session!: Dictionary<any>

  @Prop() purchasableZones!: Dictionary<any>[] | null

  eventSameDaySessions = {
    sessions: [],
  }

  mounted() {
    this.getSameDaySessions()
  }

  getSameDaySessions() {
    if (
      !this.event ||
      !this.event.lookup_ref ||
      !this.session ||
      !this.session.lookup_ref
    ) {
      return
    }

    this.$api
      .getEventSameDaySessions(this.event.lookup_ref, this.session.lookup_ref)
      .then(
        (response: any) => {
          this.eventSameDaySessions = response
        },
        () => {}
      )
  }

  eventHome() {
    this.$store.dispatch("shoppingCart/update", ["resetCart"])
    this.$router.push({
      name: "event",
      params: {
        event_lookup_ref: this.$route.params.event_lookup_ref,
      },
      query: this.$defaultQuery(),
    })
  }

  onSessionChange(sessionLookupRef: string) {
    if (!sessionLookupRef || sessionLookupRef == this.$route.params.session_lookup_ref)
      return

    if (this.session) {
      if (this.session.purchasable_seats_count < 1) {
        return
      }
      this.$store.dispatch("gtm/step1", {
        event: this.session.event,
        session: this.session,
      })
      this.$store.dispatch("shoppingCart/update", ["resetCart"])
      this.$router.push({
        name: "session",
        params: {
          event_lookup_ref: this.$route.params.event_lookup_ref,
          session_lookup_ref: sessionLookupRef,
        },
        query: this.$defaultQuery(),
      })
    }
  }

  onZoneChange(zoneId: number) {
    if (!zoneId || zoneId.toString() == this.$route.params.zone_id) return
    if (!this.event) return

    let zone = _.find(this.purchasableZones, {
      id: zoneId,
    })

    this.$store.dispatch("gtm/step2", {
      event: this.session.event,
      session: this.session,
      zone: zone,
      event_lookup_ref: this.$route.params.event_lookup_ref,
    })

    this.$router.push({
      name: "zone",
      params: {
        event_lookup_ref: this.$route.params.event_lookup_ref,
        session_lookup_ref: this.$route.params.session_lookup_ref,
        zone_id: (zone?.id).toString(),
      },
      query: this.$defaultQuery(),
    })
  }
}
