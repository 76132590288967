


















































































import _ from "lodash"
import "reflect-metadata"
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"
import { mapState } from "vuex"
import filters from "@/filters"
import TicketCard from "@/components/TicketCard.vue"

@Component({
  components: { TicketCard },
  computed: {
    ...mapState({
      cart: (state: any) => state.shoppingCart.cart,
    }),
  },
  filters: filters,
})
export default class Zones extends Vue {
  @Prop({ default: false }) isActive!: boolean
  @Prop({ default: null }) session!: {
    id: null
    lookup_ref: null
    start_date: string
    start_time: string
    stage_layout: Array<Dictionary<any>> | null
    session_zones: Array<Dictionary<any>>
    event: {
      name: string
      lookup_ref: string
      place: {
        id: number
        municipality: string
        name: string
      }
    }
    has_fees: boolean
  } | null

  expanded: boolean = false
  specificConditionsStatus: any = null
  confirming: boolean = false

  get isLoading(): boolean {
    return !this.session
  }

  get hasFees(): boolean {
    return this.session != null && this.session.has_fees
  }

  get sessionZones(): Array<Dictionary<any>> {
    if (!this.session) return []
    return this.session.session_zones
  }

  get totalAmount() {
    let totalAmount = 0
    _.each(this.$store.state.shoppingCart.cart, (line) => {
      let zone = _.find(this.sessionZones, {
        id: parseInt(line.sessionZoneId),
      })
      if (!zone) return
      if (line.concessionId) {
        let concession = _.find(zone.concessions, { id: line.concessionId })
        if (!concession) return
        totalAmount += parseFloat(concession.ticket_price)
      } else {
        totalAmount += parseFloat(zone.ticket_price)
      }
    })
    return totalAmount > 0 ? totalAmount : 0
  }

  get totalAmountAsCurrency(): string {
    if (this.$options.filters) {
      return this.$options.filters.currency(this.totalAmount)
    }

    return ""
  }

  get numTickets() {
    return this.$store.state.shoppingCart.cart.length
  }

  get currentZone() {
    if (!this.$route.params.zone_id) return null
    return _.find(
      _.compact(this.sessionZones),
      (x: any) => x && x.id == this.$route.params.zone_id
    )
  }

  get cartZones() {
    return this.currentZone
      ? _.union([this.currentZone], this.sessionZones)
      : this.sessionZones
  }

  beforeMount() {
    this.$store.commit("shoppingCart/loadState", this.$route.params.session_lookup_ref)
    this.expanded = this.$store.state.shoppingCart.visible
  }

  confirm() {
    if (!this.session || this.confirming) {
      return
    }
    this.confirming = true

    let lines: Array<{
      sessionzone_id: number
      concession_id: number | null
      seat_id: number
    }> = []

    _.each(this.$store.state.shoppingCart.cart, (line) => {
      lines.push({
        sessionzone_id: line.sessionZoneId,
        concession_id: line.concessionId,
        seat_id: line.seatId,
      })
    })

    const protectionToken = "p" in this.$route.query ? this.$route.query.p : null

    this.$api
      .createOrder({
        session_id: this.session.id,
        lines: lines,
        protection_token: protectionToken,
      })
      .then(
        (response: any) => {
          this.$store.dispatch("gtm/step3", {
            session: this.session,
            sessionZones: this.sessionZones,
            event_lookup_ref: this.$route.params.event_lookup_ref,
          })
          const data: { [k: string]: any } = {}
          data[this.$route.params.event_lookup_ref] = response.lookup_ref
          this.$store.commit("order/update", data)
          this.$store.dispatch("shoppingCart/update", ["resetCart"])
          this.$router.push({
            name: "order-confirm",
            params: {
              lookup_ref: response.lookup_ref,
            },
            query: this.$defaultQuery(),
          })
        },
        (error: any) => {
          if (error.response.status == 400) {
            this.$store.dispatch("ui/error", error.response.data.message)
          }
          this.confirming = false
        }
      )
  }

  isZoneInCart(zoneId: number) {
    return (
      _.findIndex(
        this.$store.state.shoppingCart.cart,
        (x: any) => x && x.sessionZoneId == zoneId
      ) > -1
    )
  }

  getZoneLinesInCart(zoneId: number) {
    const results: any = {}
    _.each(this.$store.state.shoppingCart.cart, (x: any, index: number) => {
      if (x && x.sessionZoneId == zoneId) {
        results[index] = x
      }
    })
    return results
  }

  getZoneNumTickets(zoneId: number) {
    return _.size(this.getZoneLinesInCart(zoneId))
  }

  getSeat(zoneId: number, seatId: number) {
    const zone = _.find(this.sessionZones, { id: zoneId })
    if (!zone) return null

    return _.find(zone.seats, { id: seatId })
  }

  onConcessionChanged() {
    const payload = {
      cartZones: this.cartZones,
      cart: this.$store.state.shoppingCart.cart,
      session: this.session,
    }
    this.$store.dispatch("gtm2404/checkoutZoneChangeType", payload)
  }

  @Watch("$store.state.shoppingCart.visible")
  onCartVisibleChange(value: boolean) {
    if (value != this.expanded) {
      this.expanded = value
    }
  }

  @Watch("expanded")
  onExpandedChange(value: boolean) {
    this.$store.commit("shoppingCart/toggle", value)
  }
}
